
    @import "config.scss";
 
.form {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  border: 1px solid var(--color-primary);
  padding: 20px;
  border-radius: var(--br-hard);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 1rem;
}

.logo {
  padding-inline: 0.5rem;
  position: absolute;
  top: -1rem;
  background-color: var(--color-black);
}

.keypad {
  display: flex;
  gap: 2rem;
  flex-direction: column;

  @include for-desktop-up {
    flex-direction: row;
  }
}
